import { WooCommerceOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { Tooltip } from 'common/components'
import WarningIcon from 'icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'

import { useWooCommerceProxyService } from '../../hooks'

type WooCommerceOnlineStoreConnectionStatusProps = {
  onlineStore: WooCommerceOnlineStore
}

const WoocommerceOnlineStoreConnectionStatus = ({ onlineStore }: WooCommerceOnlineStoreConnectionStatusProps) => {
  const wooCommerceService = useWooCommerceProxyService()

  const { isError } = useQuery(
    [...wooCommerceService.test.queryKeys, onlineStore.id],
    () => {
      return wooCommerceService.test(onlineStore.id)
    },
    {
      retry: false,
    }
  )
  if (isError) {
    return (
      <Tooltip content="Connection issue">
        <WarningIcon aria-label="Connection issue" className="fill-secondary-orange-200 ml-1 relative h-4 w-4" />
      </Tooltip>
    )
  }
  return null
}

export default WoocommerceOnlineStoreConnectionStatus
