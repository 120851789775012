import { animated } from '@react-spring/web'
import React from 'react'

import { Button, InputField } from 'common/components'

import { Header, RadioGroup, PreviousButton } from '../../fullPageSurvey'
import { StepProps, productTypeQuestion } from '../types'

const ProductType = ({ style, onPrevious, formikProps }: StepProps) => {
  return (
    <animated.div style={style}>
      <Header.H2>
        <PreviousButton onClick={onPrevious} />
        We made it
      </Header.H2>
      <Header.H1>{productTypeQuestion}</Header.H1>
      <div>
        <InputField className="mb-6">
          <RadioGroup
            value={formikProps.values.productType}
            onChange={value => formikProps.setFieldValue('productType', value)}
            onChangeFieldValue={(field, value) => {
              field === 'Other' && formikProps.setFieldValue('otherProductType', value)
            }}
            name="launch"
            options={[
              {
                label: 'Sporting goods',
                value: 'Sporting goods',
              },
              {
                label: 'Clothing & accessories',
                value: 'Clothing & accessories',
              },
              {
                label: 'Jewelry',
                value: 'Jewelry',
              },
              {
                label: 'Shoes',
                value: 'Shoes',
              },
              {
                label: 'Home & decor',
                value: 'Home & decor',
              },
              {
                label: 'Electronics',
                value: 'Electronics',
              },
              {
                label: 'Other',
                value: 'Other',
                isOpen: true,
                fieldValue: formikProps.values.otherProductType,
              },
            ]}
          />
        </InputField>
      </div>
      <div className="flex space-x-2 pt-4">
        <Button
          variant="primary"
          type="submit"
          className="grow"
          isLoading={formikProps.isSubmitting}
          onClick={() => {
            formikProps.submitForm()
          }}
        >
          Submit
        </Button>
      </div>
    </animated.div>
  )
}

export default ProductType
