import { OnlineStoreStatus, ShopifyApp } from '@packages/types'
import { useQuery, useQueries } from '@tanstack/react-query'
import React, { useEffect } from 'react'

import { useOnlineStoreService, useShopifyProxyService } from 'cms/onlineStores'
import { isShopify } from 'cms/onlineStores/utils'
import { useModal } from 'common/components'

import UpdateShopifyScopesModal, { UpdateShopifyScopesModalData } from './UpdateShopifyScopesModal'

const ShopifyScopesManager = () => {
  const updateScopesModal = useModal()

  const onlineStoreService = useOnlineStoreService()
  const shopifyProxyService = useShopifyProxyService()

  const { data: onlineStores = [] } = useQuery([onlineStoreService.fetchAll], onlineStoreService.fetchAll)

  const installedShopifyStores = onlineStores.filter(
    onlineStore => isShopify(onlineStore) && onlineStore.status === OnlineStoreStatus.Installed
  )

  const results = useQueries({
    queries: installedShopifyStores.map(store => ({
      queryKey: [shopifyProxyService.fetchApp, store.id],
      queryFn: () => shopifyProxyService.fetchApp(store.id),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      select: (data: ShopifyApp) => ({ ...data, store }),
    })),
  })

  const storesNeedingUpdate = results
    .map(result => result.data)
    .filter(data => !!data && data?.isUpToDate === false) as UpdateShopifyScopesModalData[]

  useEffect(() => {
    if (storesNeedingUpdate.length > 0) updateScopesModal.open()
  }, [storesNeedingUpdate])

  return (
    <>
      {updateScopesModal.isVisible && (
        <UpdateShopifyScopesModal {...updateScopesModal.modalProps} data={storesNeedingUpdate} />
      )}
    </>
  )
}

export default ShopifyScopesManager
