import { ShopifyOnlineStore } from '@packages/types'
import React from 'react'

import { ECommerceLogo } from 'cms/onlineStores/components'
import { Button, Modal, ModalProps } from 'common/components'

export type UpdateShopifyScopesModalData = {
  isUpToDate: boolean
  updateScopesUrl: string
  store: ShopifyOnlineStore
}

export interface UpdateShopifyScopesModalProps extends Omit<ModalProps, 'children'> {
  data: UpdateShopifyScopesModalData[]
}

const UpdateShopifyScopesModal = ({ data, ...modalProps }: UpdateShopifyScopesModalProps) => {
  return (
    <Modal {...modalProps} backdropClassName="z-[40]" className="z-[40]">
      <Modal.Title>
        <div className="flex flex-col space-y-2">
          <div>Kickflip app update required</div>
          <div className="text-neutral-500 font-normal text-xs">
            To continue using the Kickflip app, you need to update the app's permissions in the following stores.
          </div>
        </div>
      </Modal.Title>
      <Modal.Divider />
      <Modal.Details>
        <div className="flex flex-col space-y-4">
          {data
            .filter(({ isUpToDate }) => !isUpToDate)
            .map(({ updateScopesUrl, store }) => {
              return (
                <div
                  key={store.id}
                  className="flex justify-between [&:not(:last-child)]:border-b border-neutral-100 [&:not(:last-child)]:pb-4"
                >
                  <div className="flex space-x-2">
                    <ECommerceLogo eCommerce={store.eCommerce} />
                    <div>
                      <div className="font-medium">{store.name}</div>
                      <div className="text-xs text-neutral-500">{store.domain}</div>
                    </div>
                  </div>
                  <a href={updateScopesUrl}>
                    <Button>Update</Button>
                  </a>
                </div>
              )
            })}
        </div>
      </Modal.Details>
    </Modal>
  )
}

export default UpdateShopifyScopesModal
