import { PrestaShopOnlineStore } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { Page } from 'cms/layout'
import { usePrestashopProxyService, useOnlineStoreService } from 'cms/onlineStores'
import { Button, Card, HelperText, Input, InputField, Label, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

import { ConnectOnlineStoreFormValues } from '../../types/form'
import ConnectionWarningAlert from '../ConnectionWarningAlert'

const validationSchema = yup.object().shape({
  url: yup.string().required('Please enter your online store url'),
})

interface PrestashopOnlineStoreProps {
  onlineStore: PrestaShopOnlineStore
}

const PrestashopOnlineStoreContent = ({ onlineStore }: PrestashopOnlineStoreProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const onlineStoreService = useOnlineStoreService()
  const prestashopService = usePrestashopProxyService()

  const { data: connectionString = '' } = useQuery(
    [onlineStoreService.fetchConnectionString.queryKeys, onlineStore.id],
    () => onlineStoreService.fetchConnectionString(onlineStore.id),
    { keepPreviousData: true }
  )

  const { isError: isConnectionError } = useQuery(
    prestashopService.test.queryKeys,
    () => prestashopService.test(onlineStore.id),
    {
      retry: false,
    }
  )

  const { mutate: connect } = useMutation(
    (shopUrl: string) => prestashopService.connect({ onlineStoreId: onlineStore.id, shopUrl }),
    {
      onSuccess: () => {
        openToast('Store was successfully connected!', ToastType.success)
        queryClient.invalidateQueries(onlineStoreService.fetch.queryKeys)
      },
      onError: () => openGenericErrorToast('Could not connect to the online store.'),
    }
  )

  const formik = useFormik<ConnectOnlineStoreFormValues>({
    initialValues: { url: onlineStore.url || '' },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => connect(values.url, { onSettled: () => setSubmitting(false) }),
  })

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Page.Section>
        <Page.Aside
          title="Brand"
          description="This information comes from your ecommerce. Please change it from there if the infos is inaccurate."
        />

        <Page.Content>
          <Card className="flex flex-col flex-1">
            <Card.Section>
              <div className="flex flex-col flex-[2] border-r border-neutral-100">
                <span className="text-xs font-medium text-neutral-400 mb-4">Online store name</span>
                <span>{onlineStore.name}</span>
              </div>
              <div className="flex flex-col flex-1 text-right">
                <span className="text-xs font-medium text-neutral-400 mb-4">Default currency</span>
                <span>{onlineStore.currency}</span>
              </div>
            </Card.Section>

            <Card.Separator />

            <Card.Section className="md:flex-col">
              <InputField>
                <Label htmlFor="url">eCommerce</Label>
                <Input
                  placeholder="eCommerce"
                  id="url"
                  name="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.url && formik.errors.url != null}
                />
                {formik.touched.url && formik.errors.url != null && (
                  <HelperText hasError>{formik.errors.url}</HelperText>
                )}
              </InputField>
              {isConnectionError && <ConnectionWarningAlert connectionString={connectionString} />}
            </Card.Section>

            <Card.Separator />

            <Card.Section className="flex-col items-end">
              <Button
                variant="primary"
                type="submit"
                className="w-fit"
                disabled={!formik.isValid || formik.isSubmitting}
                isLoading={formik.isSubmitting}
              >
                Connect
              </Button>
            </Card.Section>
          </Card>
        </Page.Content>
      </Page.Section>
    </form>
  )
}

export default PrestashopOnlineStoreContent
