import { Subscription } from '@packages/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useContext, useEffect, useState } from 'react'

import { useSubscriptionService } from 'cms/subscription'
import { TenantContext } from 'common/tenant'
import { useCurrentUser } from 'common/users'
import gtm from 'utils/googleTagManager'

import { useContractService } from '../hooks'
import { ConsentStatus } from '../types'
import ConsentBanner from './ConsentBanner'
import TrackerContext from './TrackerContext'

interface TrackerProps {
  children?: React.ReactNode
}

const Tracker = ({ children }: TrackerProps) => {
  const tenant = useContext(TenantContext)
  const contractService = useContractService()
  const subscriptionService = useSubscriptionService()
  const { currentUser, isMCZRUser } = useCurrentUser()
  const [pendingEvents, setPendingEvents] = useState<{ event: string; data: object }[]>([])
  const [isInitialized, setIsInitialized] = useState(false)

  const push = (event: string, data: object = {}, subscription: Subscription) => {
    window.dataLayer?.push({ event, user_account_id: tenant, user_account_status: subscription.status, ...data })
  }

  const privacyPolicyConsentQuery = useQuery(
    contractService.fetchPrivacyPolicyConsent.queryKeys,
    contractService.fetchPrivacyPolicyConsent,
    {
      enabled: !!currentUser && !isInitialized,
      retry: false,
      onSuccess: ({ status }) => {
        if (!isMCZRUser && status === ConsentStatus.granted && GOOGLE_TAG_MANAGER_ID) {
          setIsInitialized(true)
          window._hsq!.push(['identify', { email: currentUser!.email }])
          gtm.initialize(GOOGLE_TAG_MANAGER_ID)
        }
      },
    }
  )

  const subscriptionRequest = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne, {
    enabled: !!currentUser && !isInitialized,
    onSuccess: subscription => {
      if (pendingEvents.length > 0) {
        pendingEvents.forEach(({ event, data }) => push(event, data, subscription))
        setPendingEvents([])
      }
    },
  })

  const { mutate: consentToPrivacyPolicy, isLoading: isConsenting } = useMutation(
    contractService.consentToPrivacyPolicy,
    {
      retry: false,
      onSuccess: () => privacyPolicyConsentQuery.refetch(),
    }
  )

  const send = (event: string, data: object = {}) => {
    isInitialized ? push(event, data, subscriptionRequest.data!) : setPendingEvents([...pendingEvents, { event, data }])
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window._hsq = window._hsq || []
  }, [])

  return (
    <TrackerContext.Provider value={{ send, status: privacyPolicyConsentQuery.data?.status }}>
      {!isMCZRUser &&
        !privacyPolicyConsentQuery.isLoading &&
        privacyPolicyConsentQuery.data?.status !== ConsentStatus.granted && (
          <ConsentBanner onAgree={consentToPrivacyPolicy} isLoading={isConsenting} />
        )}
      {children}
    </TrackerContext.Provider>
  )
}

export default Tracker
