import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { Page } from 'cms/layout'
import { Button } from 'common/components'

const ShopifyMultiCurrency = () => {
  const history = useHistory()
  const match = useRouteMatch()

  return (
    <Page.Section>
      <Page.Aside
        title="Multi currency"
        description="Set up the rounding and the price so your Product price is the same on your Customizer and your shopify."
      />

      <Page.Content className="flex flex-1 items-center">
        <Button className="w-fit" onClick={() => history.push(`${match.url}/currencies`)}>
          Edit currencies
        </Button>
      </Page.Content>
    </Page.Section>
  )
}

export default ShopifyMultiCurrency
