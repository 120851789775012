import { animated } from '@react-spring/web'
import React from 'react'

import { Button, InputField } from 'common/components'

import { Header, RadioGroup, PreviousButton } from '../../fullPageSurvey'
import RegisterSurveySkipButtons from '../RegisterSurveySkipButton'
import { StepProps, jobTitleQuestion } from '../types'

const JobTitle = ({ style, onNext, goToLast, onPrevious, formikProps }: StepProps) => {
  return (
    <animated.div style={style}>
      <Header.H2>
        <PreviousButton onClick={onPrevious} />
        So... close...
        <RegisterSurveySkipButtons field="jobTitle" formikProps={formikProps} onSkip={onNext} onSkipAll={goToLast} />
      </Header.H2>
      <Header.H1>{jobTitleQuestion}</Header.H1>
      <div>
        <InputField className="mb-6">
          <RadioGroup
            value={formikProps.values.jobTitle}
            onChange={value => formikProps.setFieldValue('jobTitle', value)}
            name="jobTitle"
            options={[
              {
                label: 'Founder / Owner',
                value: 'Founder / Owner',
              },
              {
                label: 'Management / Executive',
                value: 'Management / Executive',
              },
              {
                label: 'Design',
                value: 'Design',
              },
              {
                label: 'Marketing',
                value: 'Marketing',
              },
              {
                label: 'Developer',
                value: 'Developper',
              },
              {
                label: 'I work at an agency',
                value: 'I work at an agency',
              },
              {
                label: 'Freelance',
                value: 'Freelance',
              },
            ]}
          />
        </InputField>
      </div>
      <div className="flex space-x-2 pt-4">
        <Button variant="primary" onClick={onNext} className="grow">
          Next
        </Button>
      </div>
    </animated.div>
  )
}

export default JobTitle
