import { animated } from '@react-spring/web'
import React from 'react'

import { Button, Input, InputField } from 'common/components'

import { Header } from '../../fullPageSurvey'
import { StepProps, nameQuestion } from '../types'

const Name = ({ style, onNext, formikProps }: StepProps) => {
  return (
    <animated.div style={style}>
      <Header.H2>Welcome to Kickflip!</Header.H2>
      <Header.H1>
        <div>{nameQuestion}</div>
      </Header.H1>

      <div>
        <InputField className="mb-6">
          <Input
            autoFocus={!formikProps.values.name}
            className="!h-[42.5px]"
            id="name"
            name="name"
            placeholder="Bruce Wayne"
            value={formikProps.values.name}
            onChange={formikProps.handleChange}
          />
        </InputField>
      </div>
      <div className="pt-4">
        <Button className="w-full " variant="primary" onClick={onNext} disabled={!formikProps.values.name}>
          Next
        </Button>
      </div>
    </animated.div>
  )
}

export default Name
