import { User, UserRole } from '@packages/types'
import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { Button, Card, HelperText, Input, InputField, Label, Select } from 'common/components'

import { AccountFormValues } from '../types'

interface EditAccountFormProps {
  user: User
  roleOptions: { label: string; value: UserRole }[]
  onSubmit: (values: AccountFormValues, helpers: FormikHelpers<AccountFormValues>) => void
}

const accountDetailsFormValidation = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup.string().required('Please enter your email').email('Please enter a valid email'),
  role: yup.string().required('Please choose a role'),
})

const EditAccountForm = ({ user, onSubmit, roleOptions }: EditAccountFormProps) => {
  const formik = useFormik<AccountFormValues>({
    initialValues: user,
    validationSchema: accountDetailsFormValidation,
    enableReinitialize: true,
    onSubmit,
  })

  return (
    <Card className="flex flex-1 flex-col">
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="p-6">
          <InputField className="mb-6">
            <Label htmlFor="firstName">First name</Label>
            <Input
              placeholder="First name"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.firstName && formik.errors.firstName != null}
            />
            {formik.touched.firstName && formik.errors.firstName != null && (
              <HelperText hasError>{formik.errors.firstName}</HelperText>
            )}
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="lastName">Last name</Label>
            <Input
              placeholder="Last name"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.lastName && formik.errors.lastName != null}
            />
            {formik.touched.lastName && formik.errors.lastName != null && (
              <HelperText hasError>{formik.errors.lastName}</HelperText>
            )}
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              autoComplete="true"
              placeholder="email@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.email && formik.errors.email != null}
            />
            {formik.touched.email && formik.errors.email != null && (
              <HelperText hasError>{formik.errors.email}</HelperText>
            )}
          </InputField>
          <InputField className="mb-6">
            <Label htmlFor="role">Role</Label>
            <Select
              id="role"
              name="role"
              menuPlacement="auto"
              onChange={option => formik.setFieldValue('role', option?.value)}
              value={roleOptions.find(({ value }) => value === formik.values.role)}
              options={roleOptions}
              hasError={formik.touched.role && formik.errors.role != null}
            />
            {formik.touched.role && formik.errors.role != null && (
              <HelperText hasError>{formik.errors.role}</HelperText>
            )}
          </InputField>
        </div>
        <Card.Footer>
          <Button type="reset" onClick={() => formik.resetForm()} disabled={!formik.dirty || formik.isSubmitting}>
            Discard
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            isLoading={formik.isSubmitting}
          >
            Save
          </Button>
        </Card.Footer>
      </form>
    </Card>
  )
}

export default EditAccountForm
