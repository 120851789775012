import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router'

import { useOnlineStoreService } from 'cms/onlineStores'
import { IconButton } from 'common/components'
import { useGoBack } from 'common/hooks'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

import { Page, SettingsHeader, SettingsSideMenu } from '../../layout'
import OnlineStoreContentFactory from './OnlineStoreContentFactory'

const OnlineStore = () => {
  const { id: onlineStoreId } = useParams<{ id: string }>()
  const goBack = useGoBack()

  const onlineStoreService = useOnlineStoreService()

  const { data: onlineStore } = useQuery([...onlineStoreService.fetch.queryKeys, onlineStoreId], () =>
    onlineStoreService.fetch(onlineStoreId)
  )

  if (!onlineStore) return null

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <Page.Header>
          <div className="flex items-center">
            <IconButton Icon={LeftArrow} onClick={goBack} className="mr-2" aria-label="Go back" />
            <h1>{onlineStore.name}</h1>
          </div>
        </Page.Header>

        <OnlineStoreContentFactory onlineStore={onlineStore} />
      </Page>
    </main>
  )
}

export default OnlineStore
