import { animated } from '@react-spring/web'
import React from 'react'

import { Button, InputField } from 'common/components'

import { Header, RadioGroup, PreviousButton } from '../../fullPageSurvey'
import RegisterSurveySkipButtons from '../RegisterSurveySkipButton'
import { StepProps, salesStatusQuestion } from '../types'

const SalesStatus = ({ style, onNext, goToLast, onPrevious, formikProps }: StepProps) => {
  return (
    <animated.div style={style}>
      <Header.H2>
        <PreviousButton onClick={onPrevious} />A few more steps...
        <RegisterSurveySkipButtons field="salesStatus" formikProps={formikProps} onSkip={onNext} onSkipAll={goToLast} />
      </Header.H2>
      <Header.H1>{salesStatusQuestion}</Header.H1>
      <div>
        <InputField className="mb-6">
          <RadioGroup
            name="salesStatus"
            value={formikProps.values.salesStatus}
            onChange={value => formikProps.setFieldValue('salesStatus', value)}
            options={[
              {
                label: "We're just starting",
                value: "We're just starting",
              },
              {
                label: "We're already selling online or in person",
                value: "We're already selling online or in person",
              },
            ]}
          />
        </InputField>
      </div>
      <div className="flex space-x-2 pt-4">
        <Button variant="primary" onClick={onNext} className="grow">
          Next
        </Button>
      </div>
    </animated.div>
  )
}

export default SalesStatus
